.table-container {
    background: #fff;
    border-radius: 10px;
}

.table th, .table td {
    vertical-align: middle;
    text-align: center;
}

.table th {
    background: #007bff;
    color: white;
}

.badge {
    padding: 5px 10px;
    font-size: 14px;
    border-radius: 5px;
}

.badge-level {
    background: #17a2b8;
    color: white;
}

.badge-gov {
    background: #28a745;
    color: white;
}

.badge-pnfp {
    background: #ffc107;
    color: black;
}

.btn-group .btn {
    font-weight: 500;
    transition: all 0.3s ease-in-out;
}

.btn-group .btn:hover {
    transform: translateY(-2px);
}

.offcanvas {
    width: 300px;
}
