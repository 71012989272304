.file-item {
    background-color: #fff;
    border: 1px solid #ced4da;
    padding: 15px;
    display: flex
;
    align-items: center;
    justify-content: flex-start;
}

.wd-100p {
    width: 100%;
}

.file-item .row {
    align-items: center;
}

.file-item .bx {
    margin-right: 10px;
}

.file-item .fa.fa-file-image-o {
    color: #1b84e7;
}

.file-item a {
    color: #495057;
}

.card-header {
    padding: 0.75rem 1.25rem;
    margin-bottom: 0;
    background-color: rgba(0, 0, 0, 0.03);
    border-bottom: 1px solid #ced4da;
}

.card-header, .card-footer {
    position: relative;
    border-color: #dee2e6;
    padding-left: 15px;
    padding-right: 15px;
}

.card-header:first-child {
    border-radius: 0;
}

.card-table .card-header {
    padding: 20px;
    background-color: transparent;
    border-bottom: 1px solid #dee2e6;
}

.slim-card-title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    color: #343a40;
    letter-spacing: 1px;
}

.card-table .card-header .slim-card-title {
    margin-bottom: 0;
}

.tx-10 {
    font-size: 10px;
}

.card-table .table thead tr th, .card-table .table thead tr td {
    background-color: #f8f9fa;
    font-weight: 700;
    font-size: 12px;
    letter-spacing: 1px;
}