.profile-container {
    min-height: 100vh;
    /* background-color: #f8f9fa; */
}

.profile-card {
    width: 100%;
    max-width: 500px;
    background: #fff;
    /* border-radius: 8px; */
    /* box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1); */
}

.form-control {
    border-radius: 5px;
}

/* .btn {
    font-weight: bold;
    text-transform: uppercase;
} */

.spinner-border {
    width: 1.5rem;
    height: 1.5rem;
}
