.sidebar {
    width: 250px;
    background: #f8f9fa;
    padding: 10px;
    border-radius: 0px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.sidebar-header {
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 16px;
    font-weight: bold;
    /* padding: 12px; */
    /* margin-bottom: 15px;
    border-radius: 8px; */
}

.nav-link {
    display: flex;
    align-items: center;
    font-size: 15px;
    /* padding: 12px 15px; */
    border-radius: 0px;
    text-decoration: none;
    color: #333;
    font-weight: 500;
}

.nav-link i {
    font-size: 22px;

    transition: transform 0.2s ease-in-out;
}

.nav-link:hover i {
    transform: scale(1.2);
}

.nav-link:hover,
.nav-link.active {
    background: #007bff;
    color: white;
}

.sidebar .btn-primary {
    background: #007bff;
    border: none;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
}

.sidebar .btn-primary:hover {
    background: #0056b3;
}