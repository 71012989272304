.dashboard {
    padding-top: 60px;
    background-color: #f8f9fa;
    min-height: 100vh;
  }
  
  .dashboard-content {
    padding: 2rem;
  }
  
  .dashboard-header {
    text-align: center;
    margin-bottom: 2rem;
  }
  
  .dashboard-title {
    color: #333;
    font-size: 2.5rem;
    font-weight: 700;
    margin-bottom: 0.5rem;
  }
  
  .dashboard-subtitle {
    color: #6c757d;
    font-size: 1.1rem;
  }
  
  .stat-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    transition: transform 0.3s ease, box-shadow 0.3s ease;
  }
  
  .stat-card:hover {
    transform: translateY(-5px);
    box-shadow: 0 6px 12px rgba(0, 0, 0, 0.15);
  }
  
  .stat-card-content {
    display: flex;
    align-items: center;
  }
  
  .stat-card-icon {
    font-size: 2.5rem;
    color: #007bff;
    margin-right: 1rem;
  }
  
  .stat-card-info {
    flex-grow: 1;
  }
  
  .stat-card-title {
    font-size: 0.9rem;
    color: #6c757d;
    margin-bottom: 0.25rem;
  }
  
  .stat-card-value {
    font-size: 1.5rem;
    font-weight: 700;
    color: #333;
    margin-bottom: 0;
  }
  
  .chart-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    padding: 1.5rem;
    margin-bottom: 1.5rem;
    height: 100%;
  }
  
  .chart-card-title {
    color: #333;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 1rem;
  }
  
  .data-card {
    background-color: #fff;
    border-radius: 10px;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
    overflow: hidden;
  }
  
  .data-card-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 1rem 1.5rem;
    border-bottom: 1px solid #e9ecef;
  }
  
  .data-card-title {
    color: #333;
    font-size: 1.2rem;
    font-weight: 600;
    margin-bottom: 0;
  }
  
  .data-card-actions {
    display: flex;
    gap: 0.5rem;
  }
  
  .data-card-body {
    padding: 1.5rem;
  }
  
  .loading-spinner {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 200px;
  }
  
  .table-responsive {
    overflow-x: auto;
    -webkit-overflow-scrolling: touch;
  }
  
  @media (max-width: 768px) {
    .dashboard-content {
      padding: 1rem;
    }
  
    .stat-card {
      padding: 1rem;
    }
  
    .stat-card-icon {
      font-size: 2rem;
    }
  
    .stat-card-value {
      font-size: 1.2rem;
    }
  
    .chart-card,
    .data-card {
      padding: 1rem;
    }
  
    .data-card-header {
      flex-direction: column;
      align-items: flex-start;
    }
  
    .data-card-actions {
      margin-top: 1rem;
    }
  }
  
  

/* 
.services {
    background: #f8f9fa;
    box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.table {
    font-size: 14px;
} */
