.g-container {
    max-width: 900px;
    margin: auto;
}

.guideline-card {
    border-radius: 10px;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
    transition: transform 0.3s;
    background: #fff;
}

.guideline-card:hover {
    transform: scale(1.05);
}

.g-btn-primary {
    background-color: #007bff;
    border: none;
}

.g-btn-primary:hover {
    background-color: #0056b3;
}
