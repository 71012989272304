.logo-img {
    height: 40px;
    width: auto;
}

.navbar {
    height: 70px;
    border-bottom: 2px solid #dee2e6;
}
/* 
.navbar-nav .nav-link {
    font-weight: 500;
    padding: 10px 15px;
    transition: color 0.3s ease-in-out;
}

.navbar-nav .nav-link.active {
    color: #007bff;
}

.navbar-nav .nav-link:hover {
    background-color: #0056b3;
    color: #f8f9fa;
    transform: scale(1.05);
}

.btn {
    font-weight: 500;
} */


.active{
    background-color: rgb(212, 212, 230);
    color: blue;
}
 .menuitem:hover{
    background-color: rgb(212, 212, 230);
    color: blue;
 }