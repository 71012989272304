.card {
    position: relative;
    display: flex;
    flex-direction: column;
    min-width: 0;
    word-wrap: break-word;
    background-color: #fff;
    background-clip: border-box;
    border: 1px solid #ced4da;
    border-radius: 3px;
}

.card {
    border-radius: 0;
}

@media (min-width: 576px) {
    .card-columns .card {
        display: inline-block;
        width: 100%;
    }
}

.card-columns .card {
    margin-bottom: 20px;
}

.card-body {
    flex: 1 1 auto;
    padding: 1.25rem;
}

.card-body {
    padding: 20px;
}

.pd-30 {
    padding: 30px;
}

.slim-card-title {
    text-transform: uppercase;
    font-weight: 700;
    font-size: 13px;
    color: #343a40;
    letter-spacing: 1px;
}

.form-group {
    margin-bottom: 1rem;
}

.row-sm {
    margin-left: -10px;
    margin-right: -10px;
}

@media (min-width: 576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
}

.row-sm>div {
    padding-left: 10px;
    padding-right: 10px;
}

.form-control,
.dataTables_filter input {
    display: block;
    width: 100%;
    padding: 0.594rem 0.75rem;
    font-size: 0.875rem;
    line-height: 1.5;
    color: #495057;
    background-color: #fff;
    background-clip: padding-box;
    border: 1px solid #ced4da;
    border-radius: 3px;
    transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
}

.form-control,
.dataTables_filter input {
    border-radius: 0;
}

.row-sm>div {
    padding-left: 10px;
    padding-right: 10px;
}

@media (min-width: 576px) {
    .mg-sm-t-0 {
        margin-top: 0px;
    }
}

@media (min-width: 576px) {
    .col-sm {
        flex-basis: 0;
        flex-grow: 1;
        max-width: 100%;
    }
}

.pd-x-20 {
    padding-left: 20px;
    padding-right: 20px;
}

.step-header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    width: 100%;
  }
  .step-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    flex: 1;
    text-align: center;
  }
  .circle {
    width: 45px;
    height: 45px;
    line-height: 45px;
    border-radius: 50%;
    background: #ddd;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
    text-align: center;
    color: black;
    font-size: 18px;
  }
  .circle.completed {
    background: #1b84e7;
    color: white;
  }
  .step-label {
    font-size: 14px;
    font-weight: bold;
    margin-top: 5px;
    width: 120px;
    text-align: center;
  }
  .arrow {
    font-size: 24px;
    color: #ddd;
    position: absolute;
    right: -25px;
    top: 10px;
    font-weight: bold;
  }
  .arrow.completed {
    color: #1b84e7;
  }
  .step-container:last-child .arrow {
    display: none;
  }

  .custom-select {
    border-radius: 0;
    border: 1px solid #ced4da;
    padding: 10px;
    font-size: 16px;
    transition: border-color 0.3s ease-in-out;
}

.custom-select:focus {
    outline: none;
    border: 1px solid #007bff;
    box-shadow: none;
}

.custom-file-input {
    border-radius: 0;
    border: 1px solid #ced4da;
    padding: 10px;
    font-size: 16px;
}

.custom-file-input:focus {
    outline: none;
    border: 1px solid #007bff;
    box-shadow: none;
}

.text-success {
    display: block;
    margin-top: 5px;
    font-size: 14px;
}