.footer {
    /* position: fixed; */
    bottom: 0;
    width: 100%;
    border-top: 1px solid #dee2e6;
    background: #f8f9fa;
    font-size: 14px;
}

.footer a {
    color: #007bff;
    text-decoration: none;
}

.footer a:hover {
    text-decoration: underline;
}

