.footer {
    border-top: 1px solid #dee2e6;
    font-size: 14px;
    color: #333;
}

.footer-link {
    color: #007bff;
    text-decoration: none;
    font-weight: 500;
}

.footer-link:hover {
    text-decoration: underline;
}
